import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/layout'

const AboutPage = ({data}) => (
  <Layout>
      <div className="site-content">
          <article>
              <div className="post">
                  <h1>{data.markdownRemark.frontmatter.title}</h1>
                  <div
                      dangerouslySetInnerHTML={{
                          __html: data.markdownRemark.html,
                      }}
                  />
              </div>
          </article>
      </div>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
